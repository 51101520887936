#auth {
    min-height: 100vh;
    display: flex;
    width: 100%;
    flex-direction: column;
}

#auth-header {
    height: 50px;
    padding: 0.6rem;
    text-align: center;
    flex: 0 0 auto;
}

#auth-header > a > img {
    height: 100%;
}

#auth-body {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}