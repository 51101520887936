@font-face {
  font-family: 'KidkidFont';
  font-style: normal;
  font-weight: bold;
  font-display: auto;
  src: url('./fonts/KurriIslandPERSONAL-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'KidkidFont';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url('./fonts/KurriIslandPERSONAL-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'KidkidFont';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url('./fonts/KurriIslandPERSONAL-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'KidkidFont';
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url('./fonts/KurriIslandPERSONAL-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'KidkidFont';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('./fonts/KurriIslandPERSONAL-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'KidkidFont';
  font-style: normal;
  font-weight: 100;
  font-display: auto;
  src: url('./fonts/KurriIslandPERSONAL-Thin.ttf') format('truetype');
}

:root {
  --primary-color: #F59E0B;
  --secondary-color: #D004D4;
  --red-color: #DC2626;
  --green-color: #0A875A;
  --blue-color: #2563EB;
  --grey-color: #7A7A7A;
  --white: #FFFFFF;
  --black: #000000;
  --light-grey: #969696;
  --lighter-grey: #E4E4E4;
  --dark-grey: #6D6D6D;
  --pink-one: #FF79A5;
  --pink-two: #EC769D;
  --purple-color: #895889;
  --dark-white: #FFEDED;
  font-size: 14px;
  --AspectRatio-ratio: 16 / 9;
  --AspectRatio-paddingBottom: clamp(var(--AspectRatio-minHeight), calc(100% / (var(--AspectRatio-ratio))), var(--AspectRatio-maxHeight));
  --AspectRatio-maxHeight: 9999px;
  --AspectRatio-minHeight: 0px;
}
* {
  box-sizing: border-box;
}
body {
  font-size: 1rem;
  font-family: "KidkidFont" !important;
  margin: 0;
  padding: 0;
}